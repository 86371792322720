import axios from 'axios'; // Import Axios

function electionAPIData(queryParams) {  
  const data = fetch(process.env.REACT_APP_ELECTION_API_URL + queryParams)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(userData => {
            // Process the retrieved user data
            return userData;
          })
          .catch(error => {
            console.error('Error:', error);
          });
  return data;
}

export default electionAPIData;